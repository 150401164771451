import React from 'react'
import Form, { InputNumber } from '#Form'
import Dialog from '#Dialog'

const ChangePosForm = ({ _id }) => {
	const formDef = {
		_id,
		callSet: "ops/job/set-position",
	}

	return (
		<Form {...formDef}>
			<InputNumber
				decimals={0}
				decimalsSpace={0}
				label="Nuuova posizione"
				name="position"
			/>
		</Form>
	)
}

const ChangePosDialog = ({ _id, open, handleClose, handleRefresh }) => {
	const triggerClose = () => {
		handleClose()
		handleRefresh?.()
	}

	const title = 'Cambia posizione nella coda'
	return (
		<Dialog
			handleClose={triggerClose}
			open={open}
			title={title}
			width="md"
		>
			<ChangePosForm _id={_id} />
		</Dialog>
	)
}
export default ChangePosDialog
