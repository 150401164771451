import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'

import { Resource, Timer } from '../Widgets'
import EventsHistory from '../EventsHistory'
import WastesUpdate from '../WastesUpdate';
import {
	faListCheck,
	faTimer,
	faWineGlassCrack,
	faRotateLeft,
	faEdit,
} from '@fortawesome/pro-regular-svg-icons';
import ChQuantityDialog from './ChQuantity';

const odlPhaseStatusOpts = [
	{ value:'setup', label:'In preparazione' },
	{ value:'active', label:'Attiva' },
	{ value:'waiting', label:'In attesa' },
	{ value:'completed', label:'Completata' },
	{ value:'aborted', label:'Annullata' },
]

const tableDef = [
	{ label:"Nome", field:'label' },
	{ label:"Quantità", field:'quantity', type:'number' },
	{ label:"Risorsa", content:row => <Resource {...row} /> },
	{ label:"Stato", content:row =>
		odlPhaseStatusOpts.find(status => row.status === status.value)?.label || '?'
	},
	{ label:"Timer", content:row => <Timer {...row} /> },
]

export default function ODLPhasesManage() {
	const api = useContext(APICtx)
	const { odl } = useParams()
	const [ odlData, setODLData ] = useState()
	const [ ids, setIds ] = useState([])
	const [ historyPhase, setHistoryPhase ] = useState()
	const [ wastesUpdate, setWastesUpdate ] = useState()
	const [ editItem, setEditItem ] = useState()

	const handleRefresh = () =>
		api.call('ops/odl/phase-manage/list', { odl }).then(setIds)
	useEffect(() => {
		handleRefresh()
		api.call('ops/odl/get', { _id:odl }).then(setODLData)
	}, [])

	const handleReopen = ({ _id, label, handleRowRefresh }) =>
		api.dialogConfirm({
			text: 'Sei sicuro di voler riaprire la fase "' + label + '"?',
			onConfirm: () => api.call('ops/odl/phase-manage/reopen', { _id })
				.then(handleRowRefresh),
		})

	const getRow = _id => api.call('ops/odl/phase-manage/get', { _id, odl })
	const defBtns = [
		{
			icon: faTimer,
			label: 'Gestione timer',
			linkTo: row => `/ops/odl/${odl}/odl-phases/${row._id}/timers-mgm`,
		},
		{
			icon: faEdit,
			label: 'Modifica dati',
			onClick: setEditItem,
			show: api.auth.chk('ops-admin'),
		},
		{
			icon: faRotateLeft,
			label: 'Riattiva fase',
			onClick: handleReopen,
			show: row => [ 'completed' ].includes(row.status),
		},
		{
			icon: faRotateLeft,
			label: 'Attiva fase',
			onClick: handleReopen,
			show: row => [ 'waiting' ].includes(row.status),
		},
		{
			icon: faListCheck,
			label: 'Log eventi',
			onClick: setHistoryPhase,
		},
		{
			icon: faWineGlassCrack,
			label: 'Scarti',
			onClick: setWastesUpdate,
		},
	]

	const title = 'ODL' + (odlData?.code ? ' '+odlData.code : '') + ' - Riepilogo fasi'
	return (
		<Tpl title={title} backTo="/ops/odl">
			<EventsHistory {...historyPhase} open={Boolean(historyPhase)} handleClose={() => setHistoryPhase()} />
			<WastesUpdate {...wastesUpdate} open={Boolean(wastesUpdate)} handleClose={() => setWastesUpdate(null)} />
			<ChQuantityDialog {...editItem} open={Boolean(editItem)} handleClose={() => setEditItem(null)} />
			<DataList def={tableDef} rowActions={defBtns} ids={ids} getRow={getRow} />
		</Tpl>
	)
}
