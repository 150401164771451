import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Grid, Box } from '@mui/material';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'
import Search, { SearchSelect } from '#Search'

import {
	faBoxes,
	faDiamondExclamation,
	faGears,
	faPause,
	faPlay,
	faStop,
	faTimer,
	faWineGlassCrack,
	faTurnUp,
	faTurnDown,
	faUnlock,
	faGaugeCirclePlus,
	faArrowUpArrowDown,
	faArrowRightArrowLeft,
} from '@fortawesome/pro-regular-svg-icons'

import {
	Resource,
	StockItem,
	Timer,
} from '../Widgets'
import RequirementsConfirm from './RequirementsConfirm'
import DoneConfirm from './DoneConfirm'
import WastesUpdate from '../WastesUpdate'
import ODLPauseReason from './PauseReason'
import StockItemDetails from './StockItemDetails'
import { opsAreas, reasonsOpts } from '../schemas';

import ChangePosDialog from './ChangePos';
import ChangeResourceDialog from './ChangeResource';

const colors = {
	new: 'inherit',
	paused: 'rgba(255, 255, 0, 0.8)',
	running: 'rgba(0, 255, 0, 0.8)',
	preparing: 'rgba(0, 255, 255, 0.8)',
}

const tableDef = [
	{ label:"ODL", hide:[ 'xs', 'sm', 'md' ], content:row => row?.odlData?.code },
	{ label:"Articolo", content:row => <StockItem _id={row?.odlData?.stockItem} /> },
	{ label:"Fase", field:'label' },
	{ label:"Quantità", field:'quantity', type:'number' },
	{ label:"Risorsa", content:row => <Resource {...row} /> },
	{ label:"Timer", content:row => <Timer {...row} />},
]

const isPhasePaused = ({ hasRequirements, requirementsOk, timersStarted }) => {
	if(hasRequirements && !requirementsOk)
		return false
	if(timersStarted?.length)
		return false
	return true
}
const isPhaseRunning = ({ timersStarted }, timerType) =>
	Boolean(
		timersStarted
			?.filter(timer => timer.type === timerType)
			?.length
	)
const canIStartTimer = ({ hasRequirements, requirementsOk, timersStarted }, timerType) => {
	if(hasRequirements && !requirementsOk)
		return false
	const blockingEvents = timersStarted
		?.filter(timer => timer.isMine || timer.type !== timerType)
	return !blockingEvents?.length
}
const canIPauseTimer = ({ timersStarted }, timerType) => {
	const pausableEvents = timersStarted
		?.filter(timer => timer.isMine && timer.type === timerType)
	return !!pausableEvents?.length
}

const ResourceJobs = ({ resource, defBtns, ids, getRow, rowProps, resourcesOpts }) => {
	const resourceLabel = useMemo(() => {
		return resourcesOpts?.find(item => item.value === resource)?.label || '-'
	}, [ resource, resourcesOpts ])

	return (
		<Grid xs={12} xl={6} item>
			<Typography variant='h5'>{resourceLabel}</Typography>
			<DataList
				def={tableDef}
				rowActions={defBtns}
				ids={ids}
				getRow={getRow}
				rowProps={rowProps}
				autorefresh={8000}
			/>
		</Grid>
	)
}

export default function RecipePhaseList() {
	const api = useContext(APICtx)
	const { recipe } = useParams()
	const [ idsByRes, setIdsByRes ] = useState({})
	const [ filterArea, setFilterArea ] = useState('')
	const [ filterResource, setFilterResource ] = useState('')
	const [ resourcesOpts, setResourcesOpts ] = useState([])
	const [ requirementsConfirm, setRequirementsConfirm ] = useState()
	const [ doneConfirm, setDoneConfirm ] = useState()
	const [ wastesUpdate, setWastesUpdate ] = useState()
	const [ pauseItem, setPauseItem ] = useState()
	const [ stockItem, setStockItem ] = useState()
	const [ changePos, setChangePos ] = useState()
	const [ changeResource, setChangeResource ] = useState()

	const handleStart = ({ _id, handleRowRefresh }) =>
		api.call('ops/job/timer/start', { _id, type:'main' })
			.then(handleRowRefresh)
	const handlePrepStart = ({ _id, handleRowRefresh }) =>
		api.call('ops/job/timer/start', { _id, type:'preparation' })
			.then(handleRowRefresh)
	const handlePrepPause = ({ _id, handleRowRefresh }) =>
		api.call('ops/job/timer/pause', { _id, reason:reasonsOpts.find(item => item.fallback)?.value })
			.then(handleRowRefresh)
	// const handleRisePrio = ({ _id }) =>
	// 	api.call('ops/job/rise-priority', { _id })
	// 		.then(handleRefresh)
	// const handleLowerPrio = ({ _id }) =>
	// 	api.call('ops/job/lower-priority', { _id })
	// 		.then(handleRefresh)
	const handleOpenNext = ({ _id, label, handleRowRefresh }) =>
		api.dialogConfirm({
			text: 'Sei sicuro di voler aprire in anticipo la fase successiva a "' + label + '"?',
			onConfirm: () => api.call('ops/odl/phase-manage/open-next', { _id })
				.then(handleRefresh),
		})

	useEffect(() => {
		api.auth.groupsAsOptions({ acl:filterArea }).then(resourcesOpts => {
			setResourcesOpts(resourcesOpts
				.filter(resource => api.auth.groups.includes(resource.address))
			)
		})
	}, [ filterArea ])

	const handleRefresh = () => api.call('ops/job/list/by-resource', { recipe, filterArea, filterResource }).then(newObj => {
		const oldJson = JSON.stringify(idsByRes || {})
		const newJson = JSON.stringify(newObj || {})
		if(oldJson !== newJson)
			setIdsByRes(newObj)
	})

	useEffect(() => {
		handleRefresh()
		const intervalId = setInterval(() => {
			handleRefresh()
		}, 12000)
		return () => clearInterval(intervalId)
	}, [ filterArea, filterResource ])

	const getRow = _id => api.call('ops/job/get', { _id })
	const defBtns = [
		{
			icon: faDiamondExclamation,
			label: 'Conferma requisiti',
			onClick: setRequirementsConfirm,
			show: row => Boolean(row.hasRequirements && !row.requirementsOk),
		},
		{
			icon: faGears,
			label: 'Avvia timer preparazione',
			onClick: handlePrepStart,
			show: row => canIStartTimer(row, 'preparation'),
		},
		{
			icon: faPause,
			label: 'Pausa timer preparazione',
			onClick: handlePrepPause,
			show: row => canIPauseTimer(row, 'preparation'),
		},
		{
			icon: faPlay,
			label: 'Avvia timer',
			onClick: handleStart,
			show: row => canIStartTimer(row, 'main'),
		},
		{
			icon: faPause,
			label: 'Pausa timer',
			onClick: setPauseItem,
			show: row => canIPauseTimer(row, 'main'),
		},
		{
			icon: faStop,
			label: 'Termina fase',
			// onClick: handleFinish,
			onClick: setDoneConfirm,
			show: isPhasePaused,
		},
		{
			icon: faWineGlassCrack,
			label: 'Scarti',
			onClick: setWastesUpdate,
			show: row => Boolean(!row.hasRequirements || row.requirementsOk),
		},
		{
			icon: faBoxes,
			label: 'Dettagli articolo',
			onClick: row => setStockItem(row?.odlData?.stockItem),
		},
		{
			icon: faArrowRightArrowLeft,
			label: 'Sposta in un\'altra risorsa',
			onClick: setChangeResource,
			collapse: true,
		},
		{
			icon: faTimer,
			label: 'Gestione timer',
			linkTo: row => `/ops/odl/${row.odl}/odl-phases/${row._id}/timers-mgm`,
			show: api.auth.chk('ops-admin'),
			collapse: true,
		},
		{
			icon: faGaugeCirclePlus,
			label: 'Avvia adesso fase successiva',
			onClick: handleOpenNext,
			collapse: true,
		},
		{
			icon: faArrowUpArrowDown,
			label: 'Sposta nella coda',
			onClick: setChangePos,
			show: api.auth.chk('ops-admin'),
			collapse: true,
		},
		// {
		// 	icon: faTurnUp,
		// 	label: 'Anticipa nella coda',
		// 	onClick: handleRisePrio,
		// 	show: api.auth.chk('ops-admin'),
		// 	collapse: true,
		// },
		// {
		// 	icon: faTurnDown,
		// 	label: 'Ritarda nella coda',
		// 	onClick: handleLowerPrio,
		// 	show: api.auth.chk('ops-admin'),
		// 	collapse: true,
		// },
	]
	const rowProps = row => {
		let timerPhase = 'new'
		if(isPhaseRunning(row, 'main'))
			timerPhase = 'running'
		else if(isPhaseRunning(row, 'preparation'))
			timerPhase = 'preparing'
		else if(row.timerTotal + row.prepTimerTotal > 0)
			timerPhase = 'paused'
		return {
			backgroundColor: colors[timerPhase],
		}
	}

	return (
		<Tpl title="Coda lavori">
			<Search>
				{ api.auth.chk('ops-admin') && (
					<SearchSelect label="Filtro reparto" name="src-opsarea" onChange={setFilterArea} options={opsAreas} emptyLabel="-- Tutti i reparti --" />
				)}
				{ resourcesOpts?.length > 1 && (
					<SearchSelect
						label="Filtro risorsa"
						name="src-opsresource"
						onChange={setFilterResource}
						options={resourcesOpts}
						emptyLabel="-- Tutte le risorse --"
					/>
				)}
			</Search>
			<RequirementsConfirm {...requirementsConfirm} open={Boolean(requirementsConfirm)} handleClose={() => setRequirementsConfirm(null)} />
			<DoneConfirm {...doneConfirm} open={Boolean(doneConfirm)} handleRefresh={handleRefresh} handleClose={() => setDoneConfirm(null)} />
			<WastesUpdate {...wastesUpdate} open={Boolean(wastesUpdate)} handleClose={() => setWastesUpdate(null)} />
			<ODLPauseReason {...pauseItem} open={Boolean(pauseItem)} handleClose={() => setPauseItem()} />
			<StockItemDetails _id={stockItem} open={Boolean(stockItem)} handleClose={() => setStockItem()} />
			<ChangePosDialog _id={changePos?._id} open={Boolean(changePos)} handleClose={() => setChangePos()} handleRefresh={handleRefresh} />
			<ChangeResourceDialog _id={changeResource?._id} open={Boolean(changeResource)} handleClose={() => setChangeResource()} handleRefresh={handleRefresh} />

			<Grid container spacing={2} direction='row'>
				{ Object.keys(idsByRes).map(
					resource => (
						<ResourceJobs
							resource={resource}
							defBtns={defBtns}
							rowActions={defBtns}
							ids={idsByRes[resource]}
							getRow={getRow}
							rowProps={rowProps}
							resourcesOpts={resourcesOpts}
						/>
					)
				)}
			</Grid>
		</Tpl>
	)
}
