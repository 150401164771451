import React, { useContext, useEffect, useMemo, useState } from 'react'
import Form, { InputNumber } from '#Form'
import Dialog from '#Dialog'

import APICtx from '#api'

const ChQuantityForm = ({ _id, quantity }) => {
	const [ data, setData ] = useState({})

	useEffect(() => {
		setData({ quantity })
	}, [ _id ])

	const formDef = {
		_id,
		callSet: "ops/odl/phase-manage/update",
		data, setData,
	}

	return (
		<Form {...formDef}>
			<InputNumber decimals={0} label="Quantità pezzi completati" name="quantity" />
		</Form>
	)
}

const ChQuantityDialog = ({ _id, label, quantity, open, handleClose, handleRowRefresh}) => {
	const triggerClose = () => {
		handleClose()
		// handleRefresh?.()
		handleRowRefresh?.()
	}

	const title = useMemo(() =>
		'Modifica fase' + (label ? ' - '+label : ''),
		[ label ],
	)

	return (
		<Dialog
			handleClose={triggerClose}
			open={open}
			title={title}
			width="md"
		>
			<ChQuantityForm _id={_id} quantity={quantity} />
		</Dialog>
	)
}
export default ChQuantityDialog
