export const reasonsOpts = [
	{ value:'completed', label:'Fine fase' },
	{ value:'failure', label:'Guasto' },
	{ value:'work-shift', label:'Pranzo / Fine turno' },
	{ value:'sandblasting', label:'Sabbiatura stampo (solo fonderia)' },
	{ value:'support', label:'Supporto ad altro operatore' },
	{ value:'other', label:'Altro', fallback:true },
]

export const timerTypes = [
	{ value:'timer-started', label:'Timer avviato' },
	{ value:'timer-stopped', label:'Timer fermato' },
	{ value:'phase-closed', label:'Fase chiusa' },
	{ value:'requirements-ok', label:'Requisiti confermati' },
	{ value:'waste-update', label:'Aggiornamento scarti' },
]

export const opsAreas = [
	{ value:'ops-area-carpenteria', label:'Carpenteria' },
	{ value:'ops-area-fonderia', label:'Fonderia' },
	{ value:'ops-area-lucidatura', label:'Lucidatura' },
	{ value:'ops-area-meccanica', label:'Meccanica' },
	{ value:'ops-area-montaggio', label:'Montaggio' },
	{ value:'ops-area-siliconatura', label:'Siliconatura' },
]