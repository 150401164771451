import React, { useContext, useState, useEffect } from 'react'
import Form, { Select } from '#Form'
import Dialog from '#Dialog'

import APICtx from '#api'

const ChangeResourceForm = ({ _id }) => {
	const api = useContext(APICtx)
	const [ resourcesOpts, setResourcesOpts ] = useState([])

	useEffect(() => {
		api.auth.groupsAsOptions().then(resourcesOpts => {
			setResourcesOpts(resourcesOpts
				.filter(resource => api.auth.groups.includes(resource.address))
			)
		})
	}, [])

	const formDef = {
		_id,
		callSet: "ops/job/set-resource",
	}

	return (
		<Form {...formDef}>
			<Select label="Nuova risorsa" name="resource" options={resourcesOpts} emptyLabel="-- selezionare una risorsa --" />
		</Form>
	)
}

const ChangeResourceDialog = ({ _id, open, handleClose, handleRefresh }) => {
	const triggerClose = () => {
		handleClose()
		handleRefresh?.()
	}

	const title = 'Cambia risorsa'
	return (
		<Dialog
			handleClose={triggerClose}
			open={open}
			title={title}
			width="md"
		>
			<ChangeResourceForm _id={_id} />
		</Dialog>
	)
}
export default ChangeResourceDialog
