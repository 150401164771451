import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
// import Search, { SearchText, SearchSelect, SearchAutoComplete } from '#Search'
import DataList from '#DataList'
import { Box, TextField, Typography } from '@mui/material';

import {
	gfxTimer,
} from './Widgets'

const tableDef = [
	{ label:"ODL", field:'odlCode' },
	// { label:"Tempo", field:'timerTotal' },
	{ label:"Tempo", field:'timerTotal', type:'datetime-interval' },
	{ label:"Quantità", field:'quantity', type:'number' },
]

export default function ODLList() {
	const api = useContext(APICtx)
	const { recipe, phase } = useParams()
	const [ ids, setIds ] = useState([])
	const [ stats, setStats ] = useState([])
	const [ recipeData, setRecipeData ] = useState()

	const handleRefresh = () => Promise.all([
		api.call('ops/phase/stats/summary', { phase }).then(summary => {
			const quantity = summary?.quantity ? (summary.quantity / 10000) : 0
			const count = summary?._count || 0
			const quantityAvg = quantity && count ? quantity / count : 0
			const timerTotal = summary?.timerTotal || 0
			const timeAvgMillis = timerTotal && quantity ? timerTotal / quantity : 0

			let timeAvgSec = timeAvgMillis > 1 ? Math.round(timeAvgMillis/1000) : 0
			setStats({
				quantityAvg,
				timeAvgSec,
			})
		}),
		api.call('ops/phase/stats/list', { phase }).then(setIds),
	])
	useEffect(() => {
		handleRefresh()
		api.call('ops/recipe/get', { _id:recipe }).then(setRecipeData)
	}, [])

	const getRow = _id => api.call('ops/phase/stats/get', { _id })
	const defBtns = []

	const title = (recipeData?.label ? recipeData.label+' - ' : '') + 'Riepilogo tempi'
	return (
		<Tpl title={title}>
			<Box>
				<Typography variant='h6' sx={{ mb:1 }}>Statistiche:</Typography>
				<TextField sx={{ mb:1 }} label="Quantità media" variant="outlined" value={stats.quantityAvg || ''} disabled fullWidth />
				<TextField sx={{ mb:1 }} label="Tempo medio per pezzo" variant="outlined" value={gfxTimer(stats.timeAvgSec) || ''} disabled fullWidth />
			</Box>
			<DataList def={tableDef} rowActions={defBtns} ids={ids} getRow={getRow} pageSize={30} />
		</Tpl>
	)
}
