import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { ListItemIcon, ListItemText } from '@mui/material';

const FAIcon = ({ icon, icon2 }) => (
	<span className="fa-layers fa-fw fa-lg">
		<FontAwesomeIcon icon={icon} />
		{ icon2 && <FontAwesomeIcon icon={icon2} /> }
	</span>
)

const BtnItem = ({ icon, icon2, label, linkTo, onClick, show=true }) => {
	const display = icon ? <FAIcon icon={icon} icon2={icon2} /> : label
	return show ? (
		<Tooltip title={label}>
			<Button
				component={linkTo ? Link : null}
				to={linkTo}
				onClick={onClick}
			>
				{display}
			</Button>
		</Tooltip>
	) : null
}

const BtnList = ({ def }) => {}

const CollapsedItem = ({ icon, icon2, label, linkTo, onClick, show=true }) => (
	<Tooltip title={label}>
		<MenuItem
			component={linkTo ? Link : null}
			to={linkTo}
			onClick={onClick}
		>
			{ icon && (
				<ListItemIcon>
					<FAIcon icon={icon} icon2={icon2} />
				</ListItemIcon>
			)}
			<ListItemText>{label}</ListItemText>
		</MenuItem>
	</Tooltip>
)

const CollapsedMenu = ({ anchorEl, setAnchorEl, def }) => {
	const open = Boolean(anchorEl)

	const handleClose = () => setAnchorEl(null)

	return (
		<Menu
			id="basic-menu"
			anchorEl={anchorEl}
			open={open}
			onClose={handleClose}
			MenuListProps={{
				'aria-labelledby': 'basic-button',
			}}
		>
			{ def
				.map((defItem, itemIdx) => <CollapsedItem key={'m_'+itemIdx} {...defItem} /> )
			}
		</Menu>
	)
}

export default function BtnPad({ def }) {
	const [ anchorEl, setAnchorEl ] = useState(null)

	const handleOpenCollapsed = event =>
		setAnchorEl(event.currentTarget)

	const defBtns = def
		?.filter(defItem => !defItem?.collapse)
		?.filter(defItem => !defItem.hasOwnProperty('show') || defItem?.show)
		|| []
	const defCollapsed = def
		?.filter(defItem => defItem?.collapse)
		?.filter(defItem => !defItem.hasOwnProperty('show') || defItem?.show)
		|| []

    return (
		<>
			<ButtonGroup variant="outlined">
				{ defBtns.map((defItem, itemIdx) =>
					<BtnItem key={'b_'+itemIdx} {...defItem} />
				)}
				{ Boolean(defCollapsed.length) && (
					<BtnItem
						key='_collapsed'
						icon={faEllipsisVertical}
						label='...'
						onClick={handleOpenCollapsed}
					/>
				)}
			</ButtonGroup>
			<CollapsedMenu
				anchorEl={anchorEl}
				setAnchorEl={setAnchorEl}
				def={defCollapsed}
			/>
		</>
    )
}
