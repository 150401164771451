import React, { useState, useContext, useEffect } from 'react';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Search, { SearchText } from '#Search'
import DataList from '#DataList'
import Fab from '#Fab'

import StockItemEdit from './Edit'

import {
	faPlus,
	faEdit,
	faTrash,
	faTableCellsRowLock,
	faSync,
} from '@fortawesome/pro-regular-svg-icons'

const tableDef = [
	{ label:"Codice", field:'code' },
	{ label:"Descrizione", field:'description', type:'multiline' },
	{ label:"Note tecniche", field:'techNotes', type:'multiline' },
	{ label:"SAP", field:'sap', type:'boolean', trueIcon:faTableCellsRowLock, falseIcon:null, tooltip:row => row.sap || '-' },
	{ label:"Da sincronizzare", field:'resync', type:'boolean', trueIcon:faSync, falseIcon:null, tooltip:'In coda per la sincronizzazione' },
]

export default function StockItemList() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])
	const [ editItem, setEditItem ] = useState()
	const [ filterString, setFilterString ] = useState('')
	
	const handleDelete = ({ _id, code }) =>
		api.dialogConfirm({
			text: 'Sei sicuro di voler eliminare l\'articolo "' + code + '"?',
			onConfirm: () => api.call('ops/stock-item/delete', { _id })
				.then(handleRefresh),
		})
	const handleResync = ({ _id, code, handleRowRefresh }) =>
		api.dialogConfirm({
			text: 'Sei sicuro di voler forzare la sincronia sull\'articolo "' + code + '"?',
			onConfirm: () => api.call('ops/stock-item/resync', { _id })
				.then(handleRowRefresh),
		})

	const handleRefresh = () =>
		api.call('ops/stock-item/list', { filterString }).then(setIds)
	useEffect(() => {
		handleRefresh()
	}, [ filterString ])

	const handleEditClose = async () =>
		setEditItem(null)

	const getRow = _id => api.call('ops/stock-item/get', { _id })
	const defBtns = [
		{
			icon: faEdit,
			label: 'Modifica',
			onClick: setEditItem,
			show: row => !row.sap,
		},
		{
			icon: faTrash,
			label: 'Elimina',
			onClick: handleDelete,
			show: row => !row.sap,
		},
		{
			icon: faSync,
			label: 'Forza una nuova sincronizzazione da SAP',
			onClick: handleResync,
			show: row => row.sap && api.auth.chk('ops-admin'),
		},
	]

	return (
		<Tpl title="Articoli">
			<StockItemEdit {...editItem} open={Boolean(editItem)} handleRefresh={handleRefresh} handleClose={handleEditClose} />
			<Search>
				<SearchText label="Cerca" name="src-text" onChange={setFilterString} />
			</Search>
			<DataList def={tableDef} rowActions={defBtns} ids={ids} getRow={getRow} pageSize={30} />
			<Fab label="Crea articolo" icon={faPlus} onClick={() => setEditItem({ _id:null })} />
		</Tpl>
	)
}
