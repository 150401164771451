import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'

import { Resource } from '../Widgets'
import ODLPhaseEdit from './ODLPhaseEdit'

import {
	faEdit,
} from '@fortawesome/pro-regular-svg-icons'

const tableDef = [
	{ label:"Nome", field:'label' },
	{ label:"Quantità", field:'quantity', type:'number' },
	{ label:"Risorsa", content:row => <Resource {...row} /> },
	{ label:"Stato", field:'odlPhase', type:'boolean', tooltip:row => row.odlPhase ? 'Fase configurata' : 'Fase da configurare' },
]

export default function ODLPhasesSetup() {
	const api = useContext(APICtx)
	const { odl } = useParams()
	const [ odlData, setODLData ] = useState()
	const [ itemData, setItemData ] = useState()
	const [ ids, setIds ] = useState([])
	const [ editItem, setEditItem ] = useState()

	const handleRefresh = () =>
		api.call('ops/odl/phase-setup/list', { odl }).then(setIds)
	useEffect(() => {
		handleRefresh()
		api.call('ops/odl/get', { _id:odl }).then(odlData => {
			setODLData(odlData)
			odlData && api.call('ops/stock-item/get', { _id:odlData.stockItem }).then(setItemData)
		})
	}, [])

	const handleEditClose = async () =>
		setEditItem(null)

	const getRow = _id => api.call('ops/odl/phase-setup/get', { _id, odl })
	const defBtns = [
		{
			icon: faEdit,
			label: 'Configura fase',
			onClick: setEditItem,
		},
	]

	const title = (odlData?.code ? 'ODL '+odlData.code+' - ' : '') +
		(itemData?.code ? 'Art. '+itemData.code+' - ' : '') +
		'Configura fasi'

	return (
		<Tpl title={title} backTo="/ops/odl">
			<ODLPhaseEdit {...editItem} odl={odl} open={Boolean(editItem)} handleRefresh={handleRefresh} handleClose={handleEditClose} />
			<DataList def={tableDef} rowActions={defBtns} ids={ids} getRow={getRow} />
		</Tpl>
	)
}
